* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.verticalCenter {
	display: flex;
	flex-wrap: wrap;
	//align-items: center;
	align-content: center;
	justify-content: center;

	height: 100vh;
}

.verticalCenterFull {
	display: flex;
	flex-wrap: wrap;
	align-content: center;
	justify-content: center;

	//max-width: 100vw;
}

.tabela {
	//table-layout: fixed;
    //word-wrap: break-word;
	// outline: 1px solid #dee2e6;

	thead{
        z-index: 98;
        position: -webkit-sticky !important;
        position: sticky !important;
        top: 0;
		// outline: 1px solid #dee2e6;

		th{
			background-color: var(--bs-white);
			outline: 1px solid #dee2e6;
		}
    }
}

@media(max-width: 1080px) {
	html {
		font-size: 93.75%;
	}
}

@media(max-width: 720px) {
	html {
		font-size: 87.5%;
	}
}